

import {Component, Vue} from "vue-property-decorator";
import {mapState, mapStores} from "pinia";
import {useAuthStore} from "@/stores/auth";
import {AdminUser} from "@models/user";
import {MetaInfoOutput} from "vue-meta/types/vue-meta";
import {useLoadingStore} from "@/stores/loading";
import defaultLayout from "@/layouts/default-layout.vue";
import fullWidthLayout from "@/layouts/full-width-layout.vue";
import {basicPermittedRoutes} from "@/permission";

@Component({
    components: {defaultLayout, fullWidthLayout},
    computed: {
        ...mapState(useAuthStore, [
            "userId",
            "user",
            "isLoggedIn",
            "isSuperAdmin"
        ]),
        ...mapStores(useAuthStore),
        ...mapState(useLoadingStore, [
            "loading",
        ]),
    },
    metaInfo(this: App): object {
        return {
            changed: <T extends MetaInfoOutput>(newInfo: T) => {
                this.title = newInfo.titleChunk || "";
            }
        };
    }
})
export default class App extends Vue {

    title = "";
    allSections = [
        {
            title: "Content",
            items: [
                {title: "Banners", icon: "mdi-view-dashboard", link: "/banners"},
                {title: "News", icon: "mdi-newspaper-variant", link: "/news"},
                {title: "Blogs", icon: "mdi-post-outline", link: "/blogs"},
            ]
        },
        {
            title: "Package Settings",
            items: [
                {title: "Insurance Types", icon: "mdi-shape", link: "/insurance-types"},
                {title: "Insurance Companies", icon: "mdi-domain", link: "/insurance-companies"},
                {title: "Packages", icon: "mdi-package", link: "/packages"},
            ]
        },
        {
            title: "Order",
            items: [
                {title: "Orders", icon: "mdi-post-outline", link: "/orders"},
                {title: "Coupons", icon: "mdi-ticket-percent", link: "/coupons"},
            ]
        },
        {
            title: "Enquiries",
            items: [
                {title: "Contact Requests", icon: "mdi-message-text-fast-outline", link: "/contact-requests"},
                {title: "Quotations", icon: "mdi-file-document", link: "/quotations"},
            ]
        },
        {
            title: "Users",
            items: [
                {title: "Admins", icon: "mdi-account", link: "/admins"},
                {title: "Users", icon: "mdi-account", link: "/users"},
            ]
        },
        {
            title: "Settings",
            items: [
                {title: "Email Noti Config", icon: "mdi-mailbox", link: "/email-noti/config"},
                {title: "Point Config", icon: "mdi-mailbox", link: "/point/config"},
                // { title: 'Settings', icon: 'mdi-cog', link: '/settings' },
            ]
        }
    ];

    get sections() {
        if (!this.isSuperAdmin)
            return this.allSections.map(s => ({
                ...s,
                items: s.items.filter(i => basicPermittedRoutes.find(r => i.link.startsWith(r)))
            })).filter(s => s.items.length !== 0)
        return this.allSections
    }

    readonly authStore!: ReturnType<typeof useAuthStore>;
    readonly userId!: string;
    readonly user!: AdminUser | null;
    readonly isLoggedIn!: boolean;
    readonly isSuperAdmin!: boolean;
    readonly loading!: boolean;

    drawer = false;

    async mounted() {
        if (this.isLoggedIn && this.userId) {
            await this.authStore.fetchAdminUser(this.userId);
        }
    }

    clickLogout() {
        this.authStore.logout();
        this.$router.push("/");
    }

    get layout(): string {
        return (this.$route.meta?.layout || "default") + "-layout";
    }
}

